.signup-parent-panel {
  margin-bottom: 50px !important;
}

.signup-student-panel {
  margin-top: 40px !important;
}

.signup-bottom-student-action-buttons {
  margin-top: 40px;
}

@media print {
  .screen-only {
    display: none;
  }
}

.signup-confirmation-section {
  margin-top: 1.5rem;
}

.signup-confirmation-content {
  display: flex;
  align-items: center;
}

.signup-confirmation-download-badge {
  height: 40px;
  width: 120px;
}